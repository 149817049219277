import React from "react";
import {connect} from "react-redux";
import {
    getTotalCost,
    commaSeparator,
    getCategoriesIcon,
    getCategoriesColor
} from "../../../../../../utils/constants";
import Close from "../../../../../../assets/images/dashboard/close.svg";
import Line from "../../../../../../assets/images/dashboard/line.svg";
import Dining from "../../../../../../assets/images/dashboard/modal/dining.svg";
import Gas from "../../../../../../assets/images/dashboard/modal/gas.svg";
import Cart from "../../../../../../assets/images/dashboard/modal/cart.svg";
import Plane from "../../../../../../assets/images/dashboard/modal/plane.svg";
import Entertainment from "../../../../../../assets/images/dashboard/modal/entertainment.svg";
import Everything from "../../../../../../assets/images/dashboard/modal/everything.svg";
import { Spin } from "antd";




class ManualEntryTable extends React.Component {
	
    constructor(props) {
        super(props)
    }
    
    
    /**
     *
     *
     */
	render() {
		
		//
		const {
			onClose,
			fetchCardsLoading,
			onNumberChange,
			onFindCard,
			costs,
			totalCost,
			disable
		} = this.props;
		
		// 
        const { restaurant, gas, grocery, travel, recreation, other } = costs;
		
	    // 
	    return (
	    	<>
		        <div className="mt-20">
		            <div className="d-flex subline-text">
		                <img src={Line}/>
		                <p>In a typical month, how much do you spend on these categories?</p>
		            </div>
		            <div className="categories-list">
		                <div className="c-list">
		                    {/*<img src={Dining}/>*/}
		                    <img className="category-icon" src={getCategoriesIcon("Restaurants")} alt={`restaurants-icon`} style={{background: getCategoriesColor("Restaurants")}}/>
		                    <span className="c-name">Restaurants</span>
		                    <input type="text" className="category-input" autoComplete={"off"} name="restaurant" value={restaurant} onChange={onNumberChange} />
		                </div>
		                <div className="c-list">
		                    {/*<img src={Gas}/>*/}
		                    <img className="category-icon" src={getCategoriesIcon("Gas Stations")} alt={`gas-icon`} style={{background: getCategoriesColor("Gas Stations")}}/>
		                    <span className="c-name">Gas Stations</span>
		                    <input type="text" className="category-input" autoComplete={"off"} name="gas" value={gas} onChange={onNumberChange} />
		                </div>
		                <div className="c-list">
		                    {/*<img src={Cart}/>*/}
		                    <img className="category-icon" src={getCategoriesIcon("Supermarkets & Groceries")} alt={`cart-icon`} style={{background: getCategoriesColor("Supermarkets & Groceries")}}/>
		                    <span className="c-name">Supermarkets & Groceries</span>
		                    <input type="text" className="category-input" autoComplete={"off"} name="grocery" value={grocery} onChange={onNumberChange} />
		                </div>
		                <div className="c-list">
		                    {/*<img src={Plane}/>*/}
		                    <img className="category-icon" src={getCategoriesIcon("Travel")} alt={`travel-icon`} style={{background: getCategoriesColor("Travel")}}/>
		                    <span className="c-name">Travel</span>
		                    <input type="text" className="category-input" autoComplete={"off"} name="travel" value={travel} onChange={onNumberChange} />
		                </div>
		                <div className="c-list">
		                    {/*<img src={Entertainment}/>*/}
		                    <img className="category-icon" src={getCategoriesIcon("Recreation")} alt={`recreation-icon`} style={{background: getCategoriesColor("Recreation")}}/>
		                    <span className="c-name">Recreation</span>
		                    <input type="text" className="category-input" autoComplete={"off"} name="recreation" value={recreation} onChange={onNumberChange} />
		                </div>
		                <div className="c-list">
		                    {/*<img src={Everything}/>*/}
		                    <img className="category-icon" src={getCategoriesIcon("Other")} alt={`other-icon`} style={{background: getCategoriesColor("Other")}}/>
		                    <span className="c-name">Other</span>
		                    <input type="text" className="category-input" autoComplete={"off"} name="other" value={other} onChange={onNumberChange} />
		                </div>
		                <div className="c-list">
		                    <span className="c-total">Total :  ${totalCost} </span>
		                </div>
		            </div>
		        </div>
		        <div className="modal-footer border-top-0 justify-content-center">
		            <button
		                className={`btn luci-btn ${!disable ? "primary-btn" : "gray-btn" } font-weight-bold pt-3 pb-3`}
		                type="submit"
		                onClick={onFindCard}
		                disabled={disable}
		                data-dismiss="modal"
		            >
		                { fetchCardsLoading ? <Spin/> : null } Find Your Perfect Card
		            </button>
		        </div>
	    	</>
	    );
    }
    
    


}



const mapStateToProps = state => ({
	
});

const mapDispatchToProps = (dispatch) => {
    return {
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ManualEntryTable);






