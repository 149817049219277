import React from "react";
import {connect} from "react-redux";
import { Modal, Spin } from "antd";
import cloneDeep from "lodash/cloneDeep";
import {
    getTotalCost,
    commaSeparator,
    getCategoriesIcon,
    getCategoriesColor
} from "../../../../../../utils/constants";
import {setCosts} from "../../../../../../redux/modules/manualCosts/costsActions";
import Close from "../../../../../../assets/images/dashboard/close.svg";
import Line from "../../../../../../assets/images/dashboard/line.svg";
import Dining from "../../../../../../assets/images/dashboard/modal/dining.svg";
import Gas from "../../../../../../assets/images/dashboard/modal/gas.svg";
import Cart from "../../../../../../assets/images/dashboard/modal/cart.svg";
import Plane from "../../../../../../assets/images/dashboard/modal/plane.svg";
import Entertainment from "../../../../../../assets/images/dashboard/modal/entertainment.svg";
import Everything from "../../../../../../assets/images/dashboard/modal/everything.svg";
import ManualEntryTable from "./ManualEntryTable.js";

class FindCardModal extends React.Component {
    constructor(props){
        super(props)
        this.state = {
            costs: {
                grocery: "",
                gas: "",
                restaurant: "",
                other: "",
                recreation: "",
                travel: ""
            }
        }
    }

    componentDidMount() {
        this.setState({
            costs: cloneDeep(this.props.costs)
        })
    }

    onNumberChange = (event) => {
        let { name, value } = event.target;
        value = value.replace(/,/g, "")
        if((value === "" || (/^[0-9\b]+$/).test(value)) && value.length < 6) {
            // this.props.onSetCosts({[name]: commaSeparator(value)});
            this.setState({
                costs: {
                    ...this.state.costs,
                    [name]: commaSeparator(value)
                }
            })
        }
    }

    onFindCard = () => {
        const { costs } = this.state
        this.props.onSetCosts({...costs, isManualCost: true});
        localStorage.removeItem('LUCI_DATAS')
        setTimeout(() => {
            this.props.onFindingCards();
        }, 500)
    }

    render() {
        const { onClose, fetchCardsLoading, isContentOnly } = this.props;
        const { costs } = this.state;
        const { restaurant, gas, grocery, travel, recreation, other } = costs;
        const totalCost = getTotalCost(costs);
        const disable = String(totalCost || 0) === "0" || fetchCardsLoading;
        
        if (isContentOnly) {
            return (
                <div className="ant-modal-content-only">
                    <ManualEntryTable
                        fetchCardsLoading={fetchCardsLoading}
                        onNumberChange={this.onNumberChange}
                        onFindCard={this.onFindCard}
                        costs={costs}
                        totalCost={totalCost}
                        disable={disable}
                    />
                </div>
            );
        } else {
            return (
                <Modal
                    visible={true}
                    footer={null}
                    width={500}
                    className="find-cards-modal"
                    closable={false}
                >
                    <div className="modal-header border-bottom-0 p-0">
                        <h5 className="modal-title p-card-title">
                            Find your perfect card
                        </h5>
                        {onClose? 
                            <button type="button" className="close" onClick={onClose}>
                                <img src={Close}/>
                            </button>
                            :
                            null
                        }
                    </div>
                    <ManualEntryTable
                        onClose={onClose}
                        fetchCardsLoading={fetchCardsLoading}
                        onNumberChange={this.onNumberChange}
                        onFindCard={this.onFindCard}
                        costs={costs}
                        totalCost={totalCost}
                        disable={disable}
                    />
                </Modal>
            );
        }
        
    }
}

const mapStateToProps = state => ({
    costs: state.costs || {},
    fetchCardsLoading: state.cards.fetchCardsLoading || false
});

const mapDispatchToProps = (dispatch) => {
    return {
        onSetCosts: (data) => {
            dispatch(setCosts(data))
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(FindCardModal);
