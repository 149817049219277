import React, {useEffect, useState} from "react";
import ReactWOW from "react-wow"
import MediaQuery from "react-responsive"
import TitleLine from "../../assets/images/title-line.svg";
import Image1 from "../../assets/images/find-credit-card/image1.svg";
import Image2 from "../../assets/images/find-credit-card/image2.png";
import Image3 from "../../assets/images/find-credit-card/image3.svg";
import {getAuthToken} from "../../services/ApiService";

const FindCreditCard = (props) => {
  const [activeNo, setActiveNo] = useState(1);

  useEffect(() => {

    const interval = setInterval(() => {
      setActiveNo(seconds => seconds + 1 === 4 ? 1 : seconds + 1 );
    }, 7000);
    return () => clearInterval(interval);
  }, [])

  const onChange = (value) => setActiveNo(value)

  return (
    <section className="how-it-works find-your-card" data-wow-delay="0.5s" id="Support">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <ReactWOW>
              <h2>Find the <span>best</span> credit card that <span>maximizes</span> your <span>rewards</span></h2>
            </ReactWOW>
            <img src={TitleLine} className="title-line" alt=""/>
          </div>

          <MediaQuery minWidth={1025}>
            <div className="col-lg-6 hidden-md-down">
              <div className="hiw-left justify-content-center">
                <div /*className="hl-img"*/ >
                  {
                    activeNo === 1 ?
                      <ReactWOW animation="fadeInRight" delay="0.2s">
                        <div>
                          <img src={Image1} style={{width: 640, height: 775}} />
                        </div>
                      </ReactWOW> : null
                  }
                  {
                    activeNo === 2 ?
                      <ReactWOW animation="fadeInRight" delay="0.2s">
                        <div>
                          <img src={Image2} style={{width: 640, height: 775}} />
                        </div>
                      </ReactWOW> : null
                  }
                  {
                    activeNo === 3 ?
                      <ReactWOW animation="fadeInRight" delay="0.2s">
                        <div>
                          <img src={Image3} style={{width: 640, height: 775}} />
                        </div>
                      </ReactWOW> : null
                  }
                </div>
              </div>
            </div>
            <div className="col-lg-6 hiw-right-area">
              <div className="hiw-right">
                <div className="hiw-points">
                  <ReactWOW >
                    <div className="h-point">
                      <span className={`point-no cursor-pointer text-white ${activeNo === 1 ? 'active': ''}`} onClick={() => onChange(1)}>1</span>
                      <h3 className="text-white">luci helps you understand how you spend</h3>
                      <p className="text-white">Clear breakdown of total spending across all your cards by category.</p>
                    </div>
                  </ReactWOW>
                  <ReactWOW>
                    <div className="h-point">
                      <span className={`point-no cursor-pointer text-white ${activeNo === 2 ? 'active': ''}`} onClick={() => onChange(2)}>2</span>
                      <h3 className="text-white">Personalized recommendation based on your spending</h3>
                      <p className="text-white">Card recommendation based on your spending and category spending. Along with multi-year reward projections.</p>
                    </div>
                  </ReactWOW>
                  <ReactWOW >
                    <div className="h-point">
                      <span className={`point-no cursor-pointer text-white ${activeNo === 3 ? 'active': ''}`} onClick={() => onChange(3)}>3</span>
                      <h3 className="text-white">Breakdown of your spending by rewards</h3>
                      <p className="text-white">Clear breakdown of how your spending becomes rewards. No more guesstimating!</p>
                    </div>
                  </ReactWOW>
                </div>
              </div>
            </div>
          </MediaQuery>

          <MediaQuery maxWidth={1025}>
            <div className="col-lg-12 hiw-right-area">
              <div className="hiw-right">
                <div className="hiw-points">

                  <div className="h-point pl-0">
                    <h4 className="slide-header">luci helps you understand how you spend</h4>
                    <div className="image-container">
                      <img src={Image1} />
                    </div>
                  </div>

                  <div className="h-point pl-0">
                    <h4 className="slide-header">Personalized recommendation based on your spending</h4>
                    <div className="image-container">
                      <img src={Image2} />
                    </div>
                  </div>

                  <div className="h-point pl-0">
                    <h4 className="slide-header">Breakdown of your spending by rewards</h4>
                    <div className="image-container">
                      <img src={Image3} />
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </MediaQuery>

          <ReactWOW>
            <div className="col-lg-12 text-center">
              <div className="btn-area">
                <button
                    className="btn luci-btn primary-btn font-weight-bold pt-3 pb-3"
                    onClick={() => props.history.push("/dashboard")}
                >
                  Start Earning More - It's Free
                </button>
              </div>
            </div>
          </ReactWOW>
        </div>
      </div>
    </section>
  );
};

export default FindCreditCard;
