import React from "react";
import {connect} from "react-redux";
import MediaQuery from "react-responsive";
import {Link} from "react-router-dom";
import Logo from "../../../assets/images/logo.svg";
import ToggleIcon from "../../../assets/images/navbar-toggler-icon.svg";
import {onLogOut, getAuthToken} from "../../../services/ApiService";

class Header extends React.Component {

  render() {
    const { currentUser, onGoToResultPage, isFirstStepComplete } = this.props;
    const { firstName, lastName, email } = currentUser || {};
    const getInitial = () => (firstName || lastName) ? `${(firstName || '')[0] || ''}${(lastName || '')[0] || ''}` : ((email || " ")[0] || " ").toUpperCase()

    return (
      <header>
        <div className="container-fluid bg-dashboard">
          <div className="row bg-c">
            <div className="col">
              <nav className="navbar navbar-expand-lg navbar-light pt-4 pb-4">
                <Link className="navbar-brand" to="/" ><img src={Logo}/></Link>
                {/*<button
                  className="navbar-toggler"
                  type="button"
                  data-toggle="dropdown"
                  data-target="#navbarTogglerDemo02"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                >
                  <img src={ToggleIcon}/>
                </button>*/}
                <div className={`collapse navbar-collapse`} id="navbarTogglerDemo02">
                  <ul className="navbar-nav ml-auto mt-2 mt-lg-0">
                    <li className="nav-item">
                      <a href="https://blog.joinluci.com" className="nav-link">Blog</a>
                    </li>
                    <li className="nav-item">
                      <Link className="nav-link" to="/#HowItWorks">How it works</Link>
                    </li>
                    <li className="nav-item">
                      <Link to="/support" className="nav-link" >Support</Link>
                    </li>
                    <li className="nav-item">
                      <Link to="/about" className="nav-link" >About us</Link>
                    </li>
                    {/*<li className="nav-item">
                      <a className={`nav-link`} onClick={disabled && onGoToResultPage}>Your Results</a>
                    </li>*/}
                  </ul>
                </div>

                <MediaQuery maxWidth={991}>
                  <a
                    className="navbar-toggler"
                    role="button"
                    id="navbarTogglerDemo02"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <img src={ToggleIcon}/>
                  </a>
                  <div className={`collapse navbar-collapse logout-area dropdown-menu`} aria-labelledby="navbarTogglerDemo02">
                    <a href="https://blog.joinluci.com" className="nav-link lh-25" >Blog</a>
                    <Link className="nav-link lh-25" to="/#HowItWorks">How it works</Link>
                    <Link to="/support" className="nav-link lh-25" >Support</Link>
                    <Link to="/about" className="nav-link lh-25" >About us</Link>
                  </div>
                </MediaQuery>

                {/*<form className="form-inline my-2 my-lg-0 user-name btn-signup home-login">
                  <a
                    data-toggle="dropdown"
                    type="button"
                    className="nav-link"
                    data-target="#collapseLogout"
                    role="button"
                    aria-expanded="false"
                  >
                    <span className="dark-txt">{firstName || ""} { ' ' } {lastName || ""}</span>
                    <span className="short-name">{getInitial()}</span>
                  </a>
                  <div className={`collapse logout-area`} id="collapseLogout">
                    <a className={`account ${isFirstStepComplete ? "" : "cursor-not-allowed"}`} onClick={onGoToResultPage}>Your Results</a>
                    <Link className="account" to="/account">Account</Link>
                    <a onClick={onLogOut}>Logout</a>
                  </div>
                </form>*/}
                
                {getAuthToken()? 
                  <div className="form-inline my-2 my-lg-0 user-name btn-signup home-login dropdown show">
                    <a
                      className="nav-link"
                      role="button"
                      id="dropdownMenuLink"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      <span className="dark-txt">{firstName || ""} { ' ' } {lastName || ""}</span>
                      <span className="short-name">{getInitial()}</span>
                    </a>
                    <div className={`collapse logout-area dropdown-menu p-0`} aria-labelledby="dropdownMenuLink">
                      <a className={`account`} onClick={onGoToResultPage}>Your Results</a>
                      <Link className="account" to="/account">Account</Link>
                      <a onClick={onLogOut}>Logout</a>
                    </div>
                  </div>
                  :
                  null
                }


              </nav>
            </div>
          </div>
        </div>
      </header>
    );
  }
}

const mapStateToProps = state => ({
  currentUser: state.userDetails.currentUser || {},
  isFirstStepComplete: state.dashboardStep.isFirstStepComplete || false
});

export default connect(mapStateToProps,null)(Header)
